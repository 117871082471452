// background color
$bg-red-500: #FF0000FF;
$bg-purple-500: #800080FF;
$bg-green-600: #000000;
$bg-black: #000000;

$bg-blue-mix-500: #4F4F83FF;

$bg-blue-800: #04047e;
$bg-blue-600: #30308b;
$bg-blue-500: #373798;
$bg-blue-300: #487c98;
$bg-blue-250: #7ab5d7;
$bg-blue-200: #a9dffd;
$bg-white: #FFFFFFFF;

$bg-blue-400: #62c5fc;


$bg-black: #000000;
$bg-gray-light-900: #2c2c2c;
$bg-gray-light-700: #424242;
$bg-gray-light-600: #606060;
$bg-gray-light-500: #808080FF;
$bg-gray-light-400: #a4a3a3;
$bg-gray-light-200: #f1f1f1;
$bg-white: #FFFFFFFF;





// color
$red-500: #FF0000FF;
$black: #000000;
$gray-light-800: #313133;
$gray-light-700: #424242;
$gray-light-600: #606060;
$gray-light-500: #808080FF;
$gray-light-400: #a4a3a3;
$gray-light-200: #f1f1f1;
$gray-light-150: #e1e1e1;
$white: #FFFFFFFF;


$green-mix-800: #487700;
$green-500: #66ff00;
$green-yellow-600: #ADFF2FFF;
$blue-600: #4287ad;
$blue-500: #62c5fc;
$blue-400: #86d3fd;
$blue-200: #a9dffd;

// font sizes
$font-sm-8: 8px;
$font-md-11: 11px;
$font-md-12: 12px;
$font-md-13: 13px;
$font-md-14: 14px;
$font-md-15: 15px;
$font-md-16: 16px;
$font-md-18: 18px;
$font-lg-20: 20px;
$font-lg-22: 22px;
$font-lg-25: 25px;
$font-xl-30: 30px;
$font-xl-36: 36px;
$font-xl-40: 40px;
