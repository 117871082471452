@import "src/styles/variables";

.login {
  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;


    > p {
      font-size: $font-md-14;
      color: $gray-light-500;
    }

    > label {
      margin-bottom: 10px;
      font-weight: 500;
    }

    > input {
      padding: 14px;
      margin-bottom: 10px;
      outline: none;
      border: 1px solid $gray-light-500;
      letter-spacing: .5px;
      background: transparent;
    }

    > small {

      > img {
        position: absolute;
        top: 46%;
        right: 10px;
        cursor: pointer;
        opacity: .6;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.login_theme {
  input {
    color: white !important;
  }
  a {
    color:  #2eff00!important;
  }
  button {
    background-color: #2eff00 !important;
  }
}