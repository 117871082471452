.spinner {
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 50px;
  }
}