@import "src/styles/variables";


.users_styles {
  pointer-events: none;
  background-color: #f9f9ff;
  * h2 {
    cursor: inherit!important;
    text-decoration: none!important;
  }
}
.suggest {
  margin-left: 20px;

  &_title_box {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $font-md-16;
    min-height: 24px;

    > h5 {
      color: $red-500;
      font-weight: 500;
    }

    > span:last-child {
      cursor: pointer;
      margin-right: 20px;
    }

    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      animation: anim_rotate 600ms infinite linear;
    }
  }

  > small {
    opacity: .5;
  }
}

@keyframes anim_rotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(240deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.following {
  display: flex;
  justify-content: space-between;
  padding: 2px !important;
  width: 100% !important;

  * h2 {
    cursor: pointer!important;
  }
}