.post_cart {
  width: 100%;
  padding: 10px;
  border: 1px solid #e3e3e3;
  box-shadow: 2px 4px 12px -4px rgba(184,184,184,1);
  margin-bottom: 20px;
}

.post_cart_id {
  box-shadow: none!important;
  width: 100%!important;
}