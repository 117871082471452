@import "src/styles/variables";

.register {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 10px;

  div  {
    div {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      > p {
        font-size: $font-md-14;
        color: $gray-light-500;
      }
      > label {
        margin-bottom: 10px;
        font-weight: 500;
      }

      > input {
        padding: 14px;
        margin-bottom: 10px;
        outline: none;
        border: 1px solid $gray-light-500;
        letter-spacing: .5px;
        background: none;
      }
      > small {
        > img {
          position: absolute;
          top: 46%;
          right: 10px;
          cursor: pointer;
          opacity: .6;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  button {
    width: 100px;
    background-color: $bg-blue-600;
    color: $bg-white;
    opacity: .8;
    border-radius: 5px;
    cursor: pointer;
    border: none;

    &:hover {
      opacity: 1;
    }
  }
}

.register_theme {
  input {
    color: white!important;
  }
  a {
    color:  #2eff00!important;
  }
  button {
    background-color: #2eff00 !important;
  }
}

.register_radio {
  > label {
    font-size: 14px;
    font-weight: 300;
    opacity: .8;

    &:hover {
      opacity: 1;
    }
  }

}