@import "src/styles/variables";

.root {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;


  &::before {
    content: "";
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $bg-black;
    opacity: .4;
  }

  > div {
    position: absolute;
    z-index: 20;
    top: 15%;
    left: 50%;
    height: 400px;
    width: 400px;
    transform: translateX(-50%);
    background: $bg-gray-light-200;

    >button {
      border: none;
      position: absolute;
      right: 0;
      top: 0;
      opacity: .6;
      cursor: pointer;
      font-size: $font-lg-25;
      background: transparent;
      &:hover {
        opacity: 1;
      }
    }

    > h1 {
      margin: 20px;
      padding-bottom: 20px;
      text-align: center;
      font-size: $font-lg-20;
      border-bottom: 1px solid $black;
    }
  }

}
.className {
  justify-content: space-between!important;
  width: 100%!important;
  cursor: pointer;
}

.following_theme {
  background-color: #313131 !important;

}