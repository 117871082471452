@import "reset";
@import "variables";
@import "theme";
@import "flex";
@import "components/all";


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-white;

}


.main-container {
  max-width: 1000px;
  width: 100%;
  min-height: 100vh;
  margin: auto;
}

.container {
  width: 100%;
  min-height: 100vh;
}

.notFound {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;


  > h1 {
    font-size: $font-xl-40;
    font-weight: 600;
    color: $gray-light-500;
  }
}

.loading {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}


