.loadButton {
  border: none;
  width: 170px;
  height: 40px;
  margin: 20px 0;
  border-radius: 5px;
  font-size: 22px;
  cursor: pointer;
  transition: opacity 100ms ease-in-out;
  background-color: #151523;
  color: white;
  opacity: .8;

  &:hover {
    opacity: 1;
  }
}


.loading_spinner {
  width: 100%;
  height: 40vh;

  display: flex;
  align-items: center;
  justify-content: center;
}