@import "src/styles/variables";

.comment {
    > div {
      display: inline-flex;
      margin-bottom: 10px;
      padding-top: 5px;
      font-size: $font-md-14;
      color: $red-500;
      cursor: pointer;
    }
}

.input_comment {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;

  .comment_icon {
    border: 1px solid #c0c0c0;
    box-shadow: 2px 4px 12px -4px rgb(129, 129, 129);
    //pointer-events: none!important;
  }

  > div:first-child {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_onReply {
    background: transparent!important;
  }

  > input {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    padding-right: 70px;
    padding-left: 10px;
    background-color: $gray-light-150;

  }
  > button {
    border: none;
    background: transparent;
    position: absolute;
    top: 25%;
    right: 0;
    width: 70px;
    opacity: .8;
    font-size: $font-md-16;
    font-weight: 500;
    color: $bg-purple-500;
    letter-spacing: 1px;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}

.input_dark {

  > button {
    color: #30fc00 !important;
  }
  > input {
    background-color: #3b3b64 !important;
    color: white!important;
  }

}




.comment_menu {
  position: relative;

  > div:first-child {
    position: absolute;
    height: 30px;
    width: 40px;
    display:  flex;
    align-items: center;
    bottom: -14px;

    &:hover {
      .dop_down {
        visibility: visible!important;
      }
    }
  }

  span {
    cursor: pointer;
  }
}



.dop_down {
  visibility: hidden;
  position: absolute;
  z-index: 30;
  left: 10px;
  top: 25px;
  width: 100px;
  height: auto;
  background-color: $bg-white;
  border: 1px solid #e7e7e7;
  box-shadow: 19px 18px 19px -16px rgba(166, 166, 166, 1);

  span {
    padding: 8px 10px;
    &:hover {
      background-color: $bg-gray-light-200;
    }
  }
}
.menuItem {
  display: flex;
  flex-direction: column;

  span {
    padding: 8px 10px;
    opacity: .7;
    &:hover {
      background-color: $bg-gray-light-200;
      opacity: .7;
    }
  }
}

.dop_down_theme {
  background-color: #2c2c2c!important;
  border: 1px solid #413e3e;
  box-shadow: 19px 18px 19px -16px rgb(0, 0, 0);
  span {
    padding: 8px 10px;
    &:hover {
      background-color: #3f3f5b !important;
    }
  }
}


.menuItem_theme {
  background-color: #2c2c2c!important;
  border: none;
  color: white!important;
}