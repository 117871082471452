.status {
  margin-bottom: 30px;

  &_box {
    display: flex;
    align-items: center;
    width: 450px;
    height: 90px;
    padding: 0 15px;
    box-shadow: 2px 4px 12px -4px rgba(184,184,184,1);

    > img {
      width: 40px!important;
      height: 40px!important;
      object-fit: cover;
      border-radius: 50%;
    }

    .button {
      border: none;
      background: #eaeaea;
      height: 40px;
      width: 90%;
      border-radius: 30px;
      margin-left: 10px;
      color: #545454;
    }
  }
}

.button_dark {
  background: #4f4f83 !important;
  color: white!important;
}
