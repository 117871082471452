@import "src/styles/variables";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;


  &:hover {
    .moreDots {
      visibility: visible !important;
    }
  }

  div:first-child {
    > a {
      display: inline-flex;
      align-items: center;
      margin-bottom: 10px;

      > img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }

      > h6 {
        margin-left: 10px;
        color: $gray-light-600;
      }
    }
  }


  > div:not(:first-child) {
    padding: 0 20px;
  }

  .comment_content_box {
    display: flex;
    flex-direction: column;
    background-color: $bg-gray-light-200;
    border-radius: 10px;
    margin-bottom: 10px;


    > div {
      width: 100%;

      .textarea {
        border: none;
        resize: none;
        outline: none;
        width: 100%;
        height: 100px;
        margin: 5px 0;
        background: $bg-white;
      }

      > p:last-child {
        margin-right: 350px;
        width: 100%;
      }

      > p {
        line-height: 20px;
        font-size: $font-md-14;
        > span {
          white-space: nowrap;
        }
      }

    }
    .comment_content_nav {
      position: relative;
      padding-bottom: 10px!important;



      .like_box {
        display: flex;
        align-items: center;

        position: absolute;
        bottom: 10px;
        right: 0;

        > div:first-child {
          margin-right: 10px;
          opacity: .6;

        }

        .moreDots {
          width: 20px;
          margin-left: 5px;
        }
      }

      > small {
        font-size: 12.5px;
      }

      small:first-child {
        color: $gray-light-700;
      }

      small:not(:first-child) {
        margin-left: 10px;
        cursor: pointer;
        opacity: .8;
        color: $bg-black;
        font-weight: 600;

        &:hover {
          opacity: 1;
        }
      }
    }

  }
}

.comment_content_box_theme {
  background-color: black!important;
  color: white;

  small:first-child {
    color: #52529d !important;
  }
}


.readMore {
  color: $blue-600;
}

.readLess {
  color: $red-500;
}
