.header {
  position: fixed;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid gray;
  background: white;

  &_logo {
    margin-right: 30px;
  }

}

.header_theme {
  background-color: #3a3754;
}