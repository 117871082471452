@import "src/styles/variables";

.no_post {
  margin-left: 50%;
  margin-top: 70px;
  transform: translateX(-10%);
  font-size: 30px;
  font-weight: 600;
  color: $gray-light-400;

}

.loading {
  background-color: $bg-red-500;
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.posts {
  max-width: 950px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  grid-gap: 3px;


  > a {
    position: relative;
    aspect-ratio: 10/8;

    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $bg-black;
      transition: opacity 300ms ease-in-out !important;
    }

    &:hover {
      .info {
        visibility: visible;
        transition: visibility 150ms ease 350ms !important;

      }

      &:before {
        content: "";
        position: absolute;
        z-index: 0;
        opacity: .8;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $bg-black;
      }
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .info {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;

      > span {
        margin: 15px;
        font-size: $font-xl-30;
        font-weight: 600;
      }
    }
  }
}

.loadButton {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}